import React from "react";
import styled from "styled-components";
import Seo from "../layout/Seo";
import { __BREAKPOINTS } from "../../styles/utils/variables";
const Title=styled.h1`
    color: var(--darkpurple);
    font-family: "Integral CF Heavy";
    p{
        font-family: "MADE Outer Sans Light";
    }
`;
const Text = styled.div`
    @media (max-width: ${__BREAKPOINTS.sm}px){
        font-size: 14px;
    }
`
export default function InfoPage({seo, title, content}){
    return(
        <>
            {seo && <Seo {...seo} />}
            <div className="container">
                <Title>
                    {title}
                </Title>
                <Text dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </>
    )
}